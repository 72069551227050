import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Links } from '../Constants';
import { FaInstagram, FaFacebook } from 'react-icons/fa6';

function Footer() {
    return (<div className="bg-secondary mt-auto">
        <Container className="p-1">
            <p className="text-center mt-2 text-white">Follow us on social media:</p>
            <Link to={Links.INSTAGRAM} className="App-link"><FaInstagram /></Link>
            <Link to={Links.FACEBOOK} className="App-link"><FaFacebook /></Link>
        </Container>
    </div>)
}

export default Footer