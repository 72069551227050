import './App.css';
import { BrowserRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import NavBar from './components/NavBar';
import Router from './pages/Router';
import Footer from './components/Footer';

function App() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <BrowserRouter>
        <NavBar />
        <Container className="mt-3 mb-3">
          <Router />
        </Container>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
