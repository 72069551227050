import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./navbar.css";

import { Paths } from '../Constants';
import { GiShoonerSailboat } from 'react-icons/gi';

function NavBar() {
    return (

        <div className="main_banner sticky-top">
            <Navbar expand="lg" className="bg-body-tertiary navbar-expand-md">
                <Container>
                    <Navbar.Brand href="#home"><GiShoonerSailboat size="32px" /> Ports <span className="d-none d-md-inline">meeting </span>2024</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <LinkContainer to={Paths.HOME}><Nav.Link>Home</Nav.Link></LinkContainer>
                            <LinkContainer to={Paths.REGISTER}><Nav.Link>Register</Nav.Link></LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default NavBar